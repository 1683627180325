import * as React from "react"
import {Typography} from "@mui/material";
import BlogPost from "../../components/blog-post";


// markup
const BlogPostPage = () => {
    return (
        <BlogPost title={'Top 3 jury selection apps on the market today?'} subtitle={'Sept 21, 2020'}>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Jury Selection Apps</Typography>
            <Typography>If you were to Google jury selection apps today, you would mostly get results on apps that no
                longer exist. There were close to a dozen apps that were released in the early 2010’s that just had no
                staying power. iJuror, JuryPad, Jury Duty, Jury Tracker, Jury Strike, Jury Star… all dead. And the list
                goes on. It’s a fascinating phenomenon!</Typography>
            <br/>
            <Typography>After the initial craze of jury selection apps has settled, there are three apps that can now be
                found on the Apple App Store today. All have been built with a desire to end the frustration experienced
                by trial attorneys during jury selection. Some do a better job than others. Let’s compare the features
                of the three jury selection apps on the market today.</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Jurybox</Typography>
            <Typography>Jurybox took the most common process trial attorneys use for jury selection and digitized it.
                Think sticky notes in the Cloud, with real time collaboration. Having been designed by seasoned software
                designers and engineers, it is by far the cleanest user experience of the three. Despite how easy and
                flexible it is to use, Jurybox can still handle large jury pools, do juror scoring, and has dismissal
                tracking.</Typography>
            <br/>
            <Typography>It’s important to note that Jurybox is not just an iPad app, it’s actually a software platform
                accessed from a browser on any device that also has a native iPad app. It's meant as a firm solution,
                allowing for collaboration on cases within a law firm. So, if you see the app on the App Store you can
                download it for free, but you’ll need to sign up online to access your account. At $33.25/mo per user (paid
                annually), it’s the priciest of the solutions, but we believe it’s head and shoulders above the
                competition. If you’re skeptical, take advantage of the free trial and see for yourself:
                app.juryboxapp.com/signup</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Empirical Jury Selection</Typography>
            <Typography>Empirical Jury Selection is an iPad and Android Tablet mobile app created by Empirical Jury, a
                company that specializes in jury analysis through big data. The software allows for custom courtroom
                layouts and questions. It also has a sentiment scale and a points based scoring assigned to each juror.
                There is a collaborative feature that allows for general comments from collaborators, but does not
                appear to allow for real time edits of the jury by multiple editors.</Typography>
            <br/>
            <Typography>Empirical Jury Selection can be purchased from the Apple App Store or Google Play Store for
                $79.99. Like Jurybox, it is a more modern jury selection app. However, the app has a more cluttered user
                interface and does not allow attorneys to view comments for all jurors in the jury box, as you get with
                digital sticky notes. There is also not an easy way to track peremptory challenges during the trial.
                However, you do get custom questions and courtroom layout which is not available with Jurybox. There is
                no free trial available, so you won’t be able to try it before you buy it, but you can check out their
                videos on the website: empiricaljury.com/products/selection</Typography>

            <br/>
            <Typography variant={"h5"} color={"primary"}>Jury in a Hurry</Typography>
            <Typography>First released in 2014, Jury in a Hurry is the most established of the jury selection iPad apps
                on the market today. This app assigns a cute avatar of each juror in the jury box and courtroom gallery
                representing their demographics and quality of their responses during jury selection. It allows for
                custom questions with weights as well as a database of questions available for an additional fee. Juror
                notes can be typed or dictated as well. Juror info can be searched online without leaving the
                app.</Typography>
            <br/>
            <Typography>For only $49.99, it is the cheapest of the apps available, but possibly for good reason. It has
                an average review of 2.3 out of 5 stars on the Apple App Store. The user interface is cute but
                cluttered. It has a visual overview of all the jury info at a glance, but is a bit of a visual overload.
                It is the only app that has jury questions available for download, but charges an additional fee. Like
                Empirical Jury Selection, Jury in a Hurry does not offer a free software trial. You can however watch a
                video on their website to get a better feel of the product: juryinahurry.com</Typography>

        </BlogPost>
    );
};

export default BlogPostPage;
