import * as React from "react"
import Layout from "./layout";
import Hero from "./hero";
import {Container, Typography} from "@mui/material";

// markup
const BlogPostPage = ({title, subtitle, children}) => {
    return (
        <Layout title={title}>
            <main>
                <Hero title={title}
                      subtitle={subtitle}/>

                <Container style={{paddingTop: "3em", paddingBottom: "3em"}}>
                    <div style={{padding: '5px', backgroundColor: '#ddd'}}>
                        <Typography color={"primary"}>Disclaimer</Typography>
                        <Typography variant={"caption"}>The articles on this site are for information only and in some
                            cases only represents the opinion of the author. The information does not constitute legal
                            advice and should not be used as a substitute for obtaining legal advice from an
                            attorney.</Typography>
                    </div>

                    <br/>

                    {children}

                </Container>
            </main>
        </Layout>
    );
};

export default BlogPostPage;
